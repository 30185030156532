import './App.css';
import { useState, useEffect } from "react";

const query = `
{
  postCollection{
    items{
      title
      subtitle
    }
  }
}
`

function App() {
  // define the initial state
  const [page, setPage] = useState(null);

  console.log(process.env);

  useEffect(() => {
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setPage(data.postCollection.items[0]);
      });
  }, []);

  if (!page) {
    return (
      <div className="App">
        <header className="App-header">
          <p>Loading awesome ...</p>
        </header>
      </div>
    )
  }

  // render the fetched Contentful data
  return (
    <div className="App">
      <header className="App-header">
        <h1>{page.title}</h1>
        <p>{page.subtitle}</p>
      </header>
    </div>
  );
}

export default App;
